import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../../../config";
import { setUser } from "./authSlice";

// User type
export interface User {
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  created_at: string;
  updated_at: string;
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.API_URL}`,
    prepareHeaders: (headers: Headers, api): Headers => {
      return headers;
    },
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query(data) {
        return {
          url: "auth/login",
          method: "POST",
          body: data,
          // credentials: "include",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };
      },
      // set token to local storage
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { data: result } = (await queryFulfilled) as any;
        localStorage.setItem("token", result.access_token);
        dispatch(setUser(result.user));
        localStorage.setItem("loggedInUser", JSON.stringify(result.user));
      },
    }),

    getUser: builder.query({
      query: () => ({
        url: "/user",
        method: "GET",
        // credentials: "include",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const { data: result } = (await queryFulfilled) as any;
        dispatch(setUser(result.user));
        localStorage.setItem("loggedInUser", JSON.stringify(result.user));
      },
    }),

    logoutUser: builder.mutation({
      query: () => ({
        url: "/auth/logout",
        method: "POST",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    }),

    resetPasswordLink: builder.mutation({
      query(email) {
        return {
          url: `${config.API_URL}/auth/password/reset-link`,
          method: "POST",
          body: { email },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };
      },
    }),
    validateResetToken: builder.query({
      query: (token: string) => ({
        url: `/auth/password/reset/verify`,
        method: "POST",
        body: { token }, // Include the token in the body
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    resetPassword: builder.mutation({
      query: ({ token, newPassword }: { token: string; newPassword: string }) => ({
        url: `/auth/password/reset`, // Ensure this matches your backend route
        method: "POST",
        body: { token, password: newPassword }, // Send the token and password in the body
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    
  }),
});

export const { useValidateResetTokenQuery, useResetPasswordMutation, useGetUserQuery, useLoginUserMutation, useLogoutUserMutation, useResetPasswordLinkMutation } =
  authApi;
